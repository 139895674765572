import React from 'react';
import { LocaleTypes } from '../data/locales';

import {
  NosotrosHero,
  NosotrosHistory,
  NosotrosPeople,
  NosotrosFAQ,
  NosotrosCTA
} from '../components/nosotros'


import IndexLayout from '../layouts';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import '../styles/styles.scss';

interface NosotrosProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location
}

const nosotros: React.FC<NosotrosProps> = ({ pathContext, location }) => {

  const content = (
    <>
      <SEO title="Acerca de Ingenio Kushki" description="Acerca de Ingenio Kushki" />

      <div className="ingenio-bg-hero-nosotros">
        <NosotrosHero />
      </div>

      <NosotrosHistory />

      <NosotrosPeople />

      <NosotrosFAQ />

      <NosotrosCTA />

      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  )

  return (
    <IndexLayout location={location} content={content}  />
  )
}

export default nosotros;