import * as React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Signal from './assets/images/signal.svg';
import './styles.scss';

interface FAQProps {
  initialIcon?: any;
  question: string;
  answer: any;
}

export function FAQItem(props : FAQProps) {
  return (
    <div className='accordion' >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon  sx={{ color: "alternate.dark2" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {props.initialIcon ? props.initialIcon : <img className='accordion-icon' src={Signal} alt="pointer"/>}
          <Typography variant='subtitle2' color='text.dark'>{props.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props.answer}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}