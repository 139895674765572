import React, { FC, ReactNode, useState } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Card, CardActions, CardContent, CardMedia, Typography, Box } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Carrousel1 from './assets/images/Image-1.png';
import Carrousel2 from './assets/images/Image-2.png';
import Carrousel3 from './assets/images/Image-3.png';
import Carrousel4 from './assets/images/Image-4.png';
import Carrousel5 from './assets/images/Image-5.png';
import Carrousel6 from './assets/images/Image-6.png';
import './styles.scss'

const NosotrosCarrousel: FC = () => {
  const [slide, setSlide] = useState(1)
  
  const sliderContent = ():ReactNode => {
    if(typeof window !== 'undefined'){
      if(screen.width >= 900){
        if(slide === 1) {
          return (
            <>
              <div className="slide-item">
                <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                    <CardMedia
                      className="cash-carrousel-image"
                      component="img"
                      height="326px"
                      image={Carrousel1}
                      alt="Manuel Steremberg"
                    />
                  <CardContent>
                    <Typography variant="h4" color="text.alternate" component="div" height={"60px"}>
                      Manuel Steremberg
                    </Typography>
                    <Box className='carrousel-desc'>
                      <Typography  variant="body2" color="text.secondary">
                        SVP
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ingenio Kushki
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <OutboundLink href="mailto: manuel.steremberg@kushki.com" className="carrousel-link-email">  
                      <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }} />
                      <Typography textAlign={'center'} variant="body2" color="text.primary" sx={{ textTransform: "none", fontWeight: 'medium' }}>
                        manuel.steremberg@kushki.com
                      </Typography>
                    </OutboundLink>
                  </CardActions>
                </Card>
                <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                    <CardMedia
                      className="cash-carrousel-image"
                      component="img"
                      height="326px"
                      image={Carrousel2}
                      alt="Santiago Vargas"
                    />
                  <CardContent>
                    <Typography variant="h4" color="text.alternate" component="div" height={"60px"}>
                      Santiago Vargas
                    </Typography>
                    <Box className='carrousel-desc'>
                      <Typography  variant="body2" color="text.secondary">
                        Gerente de Unidad de Negocio/
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ingenio Kushki B2B
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <OutboundLink href="mailto: santiago.vargas@kushki.com" className="carrousel-link-email"> 
                      <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }}  />
                      <Typography textAlign={'center'} variant="body2" color="text.primary" sx={{ textTransform: "none", fontWeight: 'medium' }}>
                        santiago.vargas@kushki.com
                      </Typography>
                    </OutboundLink>
                  </CardActions>
                </Card>
                <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                    <CardMedia
                      className="cash-carrousel-image"
                      component="img"
                      height="326px"
                      image={Carrousel4}
                      alt="Yurany Moreno Pérez"
                    />
                  <CardContent>
                    <Typography variant="h4" color="text.alternate" component="div" height={"60px"}>
                      Yurany Moreno Pérez
                    </Typography>
                    <Box className='carrousel-desc'>
                      <Typography variant="body2" color="text.secondary">
                        Gerente de Unidad de Negocio/
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ingenio Kushki B2C
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <OutboundLink href="mailto: yurany.moreno@kushki.com"  className="carrousel-link-email"> 
                      <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }}  />
                      <Typography textAlign={'center'} variant="body2" color="text.primary" sx={{ textTransform: "none", fontWeight: 'medium' }}>
                        yurany.moreno@kushki.com
                      </Typography>
                    </OutboundLink>
                  </CardActions>
                </Card>
                <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                    <CardMedia
                      className="cash-carrousel-image"
                      component="img"
                      height="326"
                      image={Carrousel5}
                      alt="Juan Pablo Rubio Herrera"
                    />
                  <CardContent>
                    <Typography variant="h4" color="text.alternate" component="div" height={"60px"}>
                      Juan Pablo Rubio Herrera
                    </Typography>
                    <Box className='carrousel-desc'>
                      <Typography variant="body2" color="text.secondary">
                        Gerente Desarrollo de Negocios/
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Ingenio Kushki Financiero
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <OutboundLink href="mailto: juanpablo.rubio@kushki.com"  className="carrousel-link-email"> 
                      <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }} />
                      <Typography textAlign={'center'} variant="body2" color="text.primary" sx={{ textTransform: "none", fontWeight: 'medium' }}>
                        juanpablo.rubio@kushki.com
                      </Typography>
                    </OutboundLink>
                  </CardActions>
                </Card>
              </div>
            </>
            )
          } 
      } else {
        if(slide === 1) {
          return (
          <>
            <div className="slide-item">
              <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                  <CardMedia
                    className="cash-carrousel-image"
                    component="img"
                    height="326"
                    image={Carrousel1}
                    alt="Manuel Steremberg"
                  />
                <CardContent>
                  <Typography variant="h4" color="text.alternate">
                    Manuel Steremberg
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <br/>
                    SVP
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ingenio Kushki
                  </Typography>
                </CardContent>
                <CardActions>
                  <OutboundLink href="mailto: manuel.steremberg@kushki.com" className="carrousel-link-email"> 
                    <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }} />
                    <Typography variant="body2" color="text.primary" textAlign={'center'} sx={{ textTransform: "none", fontWeight: 'medium' }}>
                      manuel.steremberg@kushki.com
                    </Typography>
                  </OutboundLink>
                </CardActions>
              </Card>
            </div>
            <ol className="slide-indicador">
                <li>
                    <label onClick={() => setSlide(1)} className={`slide-circulo ${slide === 1 ? "active" : ""}`}>•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(2)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(3)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(4)} className="slide-circulo">•</label>
                </li>
            </ol>
          </>
        )} else if(slide === 2){
          return (
          <>
            <div className="slide-item">
              <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                  <CardMedia
                    className="cash-carrousel-image"
                    component="img"
                    height="326"
                    image={Carrousel2}
                    alt="Santiago Vargas"
                  />
                <CardContent>
                  <Typography variant="h4" color="text.alternate" component="div">
                    Santiago Vargas
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <br/>
                    Gerente de Unidad de Negocio/
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ingenio Kushki B2B
                  </Typography>
                </CardContent>
                <CardActions>
                  <OutboundLink href="mailto: santiago.vargas@kushki.com" className="carrousel-link-email"> 
                    <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }} />
                    <Typography variant="body2" color="text.primary" textAlign={'center'} sx={{ textTransform: "none", fontWeight: 'medium' }}>
                      santiago.vargas@kushki.com
                    </Typography>
                  </OutboundLink>
                </CardActions>
              </Card>
            </div>
            <ol className="slide-indicador">
                <li>
                    <label onClick={() => setSlide(1)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(2)} className={`slide-circulo ${slide === 2 ? "active" : ""}`}>•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(3)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(4)} className="slide-circulo">•</label>
                </li>
            </ol>
          </>
        )} else if(slide === 3){
          return (
          <>
            <div className="slide-item">
              <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                  <CardMedia
                    className="cash-carrousel-image"
                    component="img"
                    height="326"
                    image={Carrousel4}
                    alt="Yurany Moreno Pérez"
                  />
                <CardContent>
                  <Typography variant="h4" color="text.alternate" component="div">
                    Yurany Moreno Pérez
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <br/>
                    Gerente de Unidad de Negocio/
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ingenio Kushki B2C
                  </Typography>
                </CardContent>
                <CardActions>
                  <OutboundLink href="mailto: yurany.moreno@kushki.com"  className="carrousel-link-email"> 
                    <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }} />
                    <Typography variant="body2" color="text.primary" textAlign={'center'} sx={{ textTransform: "none", fontWeight: 'medium' }}>
                      yurany.moreno@kushki.com
                    </Typography>
                  </OutboundLink>
                </CardActions>
              </Card>
            </div>
            <ol className="slide-indicador">
                <li>
                    <label onClick={() => setSlide(1)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(2)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(3)} className={`slide-circulo ${slide === 3 ? "active" : ""}`}>•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(4)} className="slide-circulo">•</label>
                </li>
            </ol>
          </>
        )} else if(slide === 4){
          return (
          <>
            <div className="slide-item">
              <Card className='card cash-carrousel-card' sx={{ mr: '0.75rem', ml: '0.75rem', minHeight: '30rem'}}>
                    <CardMedia
                      className="cash-carrousel-image"
                      component="img"
                      height="326"
                      image={Carrousel5}
                      alt="Juan Pablo Rubio Herrera"
                    />
                  <CardContent>
                    <Typography variant="h4" color="text.alternate" component="div">
                      Juan Pablo Rubio Herrera
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <br/>
                      Gerente Desarrollo de Negocios/
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Ingenio Kushki Financiero
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <OutboundLink href="mailto: juanpablo.rubio@kushki.com"  className="carrousel-link-email"> 
                      <MailOutlineIcon color='primary' sx={{ mr: '0.2rem' }} />
                      <Typography variant="body2" color="text.primary" textAlign={'center'} sx={{ textTransform: "none", fontWeight: 'medium' }}>
                        juanpablo.rubio@kushki.com
                      </Typography>
                    </OutboundLink>
                  </CardActions>
                </Card>
            </div>
            <ol className="slide-indicador">
                <li>
                    <label onClick={() => setSlide(1)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(2)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(3)} className="slide-circulo">•</label>
                </li>
                <li>
                    <label onClick={() => setSlide(4)} className={`slide-circulo ${slide === 4 ? "active" : ""}`}>•</label>
                </li>
            </ol>
          </>
        )} 
      }
    }
  }

  return (
      <div className="slide">
        <div className="slide-inner">
            { 
                sliderContent()
            }
        </div>
    </div>
  )
}

export default NosotrosCarrousel