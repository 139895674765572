import React, { FC } from 'react'
import { Grid, Typography, Button, Container, Box } from '@mui/material'
import { Link } from 'gatsby'
import heroImg from './assets/images/nosotros.png';
import './styles.scss'

const NosotrosHero: FC = () => {
  return (
    <>
      <Container>
        <Grid container spacing={4} className="ing-index-hero__content" mt={"1rem"} padding={"0.5rem"}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Link to='/contacto'>
              <Button variant="outlined" sx={{ my: { md: 2, sm: 3 } }} color="primary" className="ing-index-hero__button">Contáctanos</Button>
            </Link>
          </Grid>
          <Grid sx={{ display: { xs: 'none', md: 'block' }, width: '250px' }} item xs={12} md={6} >
            <img src={heroImg} alt="intersect img" className="ing-index-hero__img"  width={"100%"}/>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default NosotrosHero;