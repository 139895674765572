import React, { FC } from 'react'
import { Typography, Container, Box } from '@mui/material';
import NosotrosCarrousel from './NosotrosCarrousel';
import './styles.scss';

const NosotrosPeople: FC = () => {
  return (
    <div className="nosotros-container">
      <Container className="nosotros-container">
        <Typography variant="h2" color="text.white" align="center" sx={{ py: 5 }} >
          El equipo de Ingenio Kushki
        </Typography>
        <Typography variant="body1" color="text.white" align="center" >
          Nos ajustamos a tus necesidades para brindarte un servicio personalizado.
        </Typography>
        <Box justifyContent="center" alignItems="center">
          <NosotrosCarrousel />
        </Box>
      </Container>
   </div>
  )
}

export default NosotrosPeople;