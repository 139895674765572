import React, {FC} from 'react';
import { Grid, Typography, Container } from '@mui/material';
import './styles.scss'

const nosotrosHistory: FC = () => {
  return (
    <Container className='container-content'>
      <Grid container lg={12} md={12} sm={12}
        spacing={{ xs: 2, md: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid className="nosotros-history-text-container" direction="column" item md={6} xs={12} sx={{ display: { xs: 'none', md: 'block' } }}  >
          <iframe
            src="https://www.youtube-nocookie.com/embed/0-e_B2vLnDc?controls=0" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen
          />
        </Grid>
        <Grid item md={6} xs={12} className="nosotros-history-text-container" direction="column">
          <Grid item md={6} sm={12} xs={12}>
          </Grid>
          <Grid md={12} sm={12} xs={12} px={4}>
            <Typography color="text.dark" variant="h2" className="nosotros-history-text">
              Nuestra historia
            </Typography>
            <br/>
            <Typography color="text.dark" variant="body2" className="nosotros-history-text">
              Vivimos en un mundo donde el tiempo es uno de los recursos más escasos que tenemos, así que <strong>Ingenio Kushki</strong> se crea para especializarse en simplificar procesos de manera creativa para <strong>devolverle</strong> tiempos a nuestros aliados mediante la automatización.
            </Typography>
            <br/>
            <Typography color="text.dark" variant="body2" className="nosotros-history-text">
              En <strong>Ingenio Kushki</strong> impulsamos la transformación de los gobiernos y empresas de América Latina, digitalizando e integrando procesos, con nuestras capacidades de procesamiento de pagos y el desarrollo de software a la medida. 
            </Typography>
            <br/>
            <Typography color="text.dark" variant="body2" className="nosotros-history-text">
              Permitimos que millones de ciudadanos y empresas tengan acceso a documentos y trámites en línea de forma fácil, rápida y segura.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default nosotrosHistory;