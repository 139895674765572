import React, {FC} from 'react'
import { Typography, Container } from '@mui/material';
import { FAQItem } from '../../FAQItem';
import './styles.scss'

const nosotrosFAQ: FC = () => {
  return (
    <Container className="container-content">
        <Typography variant="h2" color="text.alternate" align="center"  sx={{ mb: '2rem'}}>
          Preguntas frecuentes
        </Typography>
        <FAQItem
          question='¿Qué es Ingenio Kushki?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              Somos el partner tecnológico que impulsa la transformación digital en el acceso y la gestión de los documentos oficiales de América Latina.
              <ul>
                <li className="list-item">Facilitamos el acceso a la obtención y pago online de documentos legítimos de forma fácil a los ciudadanos.</li>
                <li className="list-item">Conectamos, simplificamos y automatizamos procesos internos a las empresas que operan con documentos oficiales, aumentando su productividad y agilizando los procesos para sus clientes.</li>
                <li className="list-item">Colaboramos en la transformación digital de las instituciones públicas acercando los trámites ciudadanos y  su pago a los personas a través de sitios webs seguros y fáciles de utilizar.</li>
              </ul>
            </Typography>
          }
        />
        <FAQItem
          question='¿Con qué tipo de clientes trabajamos?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              Empresas privadas y públicas, sector gobierno, que están interesados en digitalizar sus procesos relacionados con pagos.
            </Typography>
          }
        />
        <FAQItem
          question='¿Cuál es la propuesta de valor de Ingenio Kushki?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              <strong>Nuestras propuestas nacen a través de la metodología Design Thinking:</strong>
              <br/>
              <strong>Descubrimiento: </strong> entendemos a profundidad el requerimiento y los momentos clave en el proceso de uso, consulta y compra.
              <br/>
              <strong>Interpretación: </strong> abordamos la oportunidad identificada para ofrecer una solución ideal con base en datos.  
              <br/>
              <strong>Ideación: </strong> estudiamos alternativas para construir propuestas que se adapten a cada necesidad y modelo de negocio. 
              <br/>
              <strong>Experimentación: </strong> hacemos ejercicios de producto mínimo viable y beta testing para ajustar y asegurar el mejor desempeño.
              <br/>
              <strong>Evolución: </strong> monitoreamos  de cerca los resultados e iteramos sobre ellos para una mejora continua.
            </Typography>
          }
        />
        <FAQItem
          question='¿Cuáles productos tiene Ingenio Kushki?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              Certifikado, Detekta, Certificado de Tradición y Libertad, Certificado de No Propiedad, así como otras Soluciones Comerciales y Soluciones de Recaudo.
            </Typography>
          }
        />
        <FAQItem
          question='¿Qué es Certifikado?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              Es la plataforma donde potenciamos la capacidad de las empresas de gestionar servicios documentales en línea, de forma sencilla y segura, para lograr sus metas.
            </Typography>
          }
        />
        <FAQItem
          question='¿Qué es Detekta?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              Detekta es un documento que analiza el Certificado de Tradición y Libertad mediante procesos de inteligencia artificial, generando un diagnóstico detallado de la situación jurídica del inmueble.
              En segundos, Detekta emite un diagnóstico acerca del historial jurídico de la propiedad e identifica potenciales condiciones que debe tener en cuenta.
            </Typography>
          }
        />
        <FAQItem
          question='¿Qué es un Certificado de Tradición y Libertad?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              También conocido como certificado de inmuebles, es un documento muy importante si se desea acreditar legalmente la existencia de un inmueble, contiene información relevante sobre el estatus jurídico de una propiedad, en su historia tanto presente como pasada.
            </Typography>
          }
        />
        <FAQItem
          question='¿Qué es un Certificado de No Propiedad?'
          answer={
            <Typography variant="body2" color="text.dark" align="justify">
              Es una constancia que se entrega a los solicitantes que no poseen bienes inmuebles a nivel nacional, para los trámites de subsidio de vivienda y otros beneficios del gobierno.
            </Typography>
          }
        />
    </Container>
  )
}

export default nosotrosFAQ;